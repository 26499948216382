import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getPatientSelectedInstitution } from './_selected_patients';

export const getInstitutions = state => state.institutions.items;

export const getInstitutionData = institution => {
  // eslint-disable-next-line camelcase
  const { short_name, name, id, city, avatar_url: avatarUrl } = institution;
  return {
    id,
    image: { name, src: avatarUrl || '' },
    short_name,
    name,
    description: city || '',
    childrens: []
  };
};

export const getInstitutionsDataForList = createSelector([getInstitutions], institutions =>
  institutions.map(i => getInstitutionData(i))
);

export const getInstitutionsDataForSelectField = createSelector([getInstitutions], institutions =>
  institutions.map(i => ({
    label: i.name,
    value: i.id
  }))
);

/*
  TODO: refactor this. This is a bad practice. The selectors
  should not "look" to different slices based on their value.
  It seems that this domain should have a specific slice
  listening to multiple actions and acting accordingly.
  This decreases the code readability and make its understanding
  way harder.
 */
const _getInstitution = state => {
  if (state.prom.institution) {
    return state.prom.institution;
  } else if (state['prom-v2'] && state['prom-v2'].configs.institution) {
    return state['prom-v2'].configs.institution;
  } else if (Object.keys(state.professional.selectedInstitution).length) {
    return state.professional.selectedInstitution || {};
  } else if (Object.keys(state.patient.selectedInstitution).length) {
    return getPatientSelectedInstitution(state);
  }
  return {};
};

export const getUserInstitution = state => _getInstitution(state);

export const getSelectedInstBrandColor = state => {
  const institution = _getInstitution(state);
  return get(institution, 'branding.app.colors') ? institution.branding.app.colors.primary : undefined;
};

export const getInstitutionLogo = state => {
  const institution = _getInstitution(state);

  return get(institution, 'branding.app.logo_url') ? [institution.branding.app.logo_url, institution.name] : [];
};

/**
 * Eprom logo has current hierarchy:
 *  1º branding eprom
 *  2º branding institution
 *  3º branding promptly
 *
 * @returns {string} Selected logo url
 */

export const getEpromInstitutionLogo = state => {
  const institution = _getInstitution(state);
  if (institution && Object.keys(institution).length && institution.branding) {
    if (institution.branding.eprom && institution.branding.eprom.logo_url) {
      return [institution.branding.eprom.logo_url, institution.name];
    } else if (institution.branding.app && institution.branding.app.logo_url) {
      return [institution.branding.app.logo_url, institution.name];
    }
  }
  return [];
};

/**
 * Eprom color has current hierarchy:
 *  1º branding eprom
 *  2º branding institution
 *  3º branding promptly
 *
 * @returns {string} Selected logo url
 */
export const getEpromBrandColor = state => {
  const institution = _getInstitution(state);

  if (institution && Object.keys(institution).length && institution.branding) {
    if (institution.branding.eprom && institution.branding.eprom.colors) {
      return institution.branding.eprom.colors.primary;
    } else if (institution.branding.app && institution.branding.app.colors) {
      return institution.branding.app.colors.primary;
    }
  }
  return undefined;
};

/**
 * Function responsible for checking if an institution has defined branding
 * @returns {Boolean} true if has branding, false if not
 */
export const hasInstitutionBranding = state => {
  const institution = _getInstitution(state);
  return !!(
    institution &&
    Object.keys(institution).length &&
    institution.branding.app &&
    Object.keys(institution.branding.app).length
  );
};
