"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlagIsOn = exports.FeatureFlagProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var growthbook_react_1 = require("@growthbook/growthbook-react");
var FeatureFlagProvider = function (_a) {
    var provider = _a.provider, children = _a.children;
    provider.loadFeatures({ autoRefresh: true });
    return (0, jsx_runtime_1.jsx)(growthbook_react_1.GrowthBookProvider, __assign({ growthbook: provider }, { children: children }), void 0);
};
exports.FeatureFlagProvider = FeatureFlagProvider;
var useFeatureFlagIsOn = function (flagName) { return (0, growthbook_react_1.useFeatureIsOn)(flagName.toLowerCase()); };
exports.useFeatureFlagIsOn = useFeatureFlagIsOn;
