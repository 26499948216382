import { createSelector } from 'reselect';

export const getPatient = state => {
  if (state.institutionPatient && state.institutionPatient.selected) {
    return state.institutionPatient.selected;
  }
  return state.patient;
};

export const getPatientId = createSelector([getPatient], patient => (patient ? patient.id : undefined));
