import { createSelector } from 'reselect';
import { getDiseaseItems, getSelectedDisease } from './selected_diseases';
import { getPatient } from './selected_patient';
import { getIsPrivatePractice, getProfessional, getSelectedInstitution } from './selected_professional';
import { getUser } from './selected_user';

export const getUserUrl = createSelector([getUser], user => (user ? `/users/${user.uuid}` : ''));

export const getPreferencesURL = createSelector([getUserUrl], userURL => `${userURL}/preferences`);

export const getUserAvatarURL = createSelector([getUserUrl], userURL => `${userURL}/avatar`);

export const getInstitutionPatientsURL = createSelector(
  [getSelectedInstitution],
  institution => `${institution.url}/patients`
);

export const getInstitutionPatientsSearchURL = createSelector(
  [getInstitutionPatientsURL],
  baseURL => `${baseURL}/search`
);

export const getInstitutionPatientURL = createSelector(
  [getInstitutionPatientsURL, getPatient],
  (institutionURL, patient) => `${institutionURL}/${patient.id}`
);

export const getInstitutionPatientEditURL = createSelector([getInstitutionPatientURL], url => `${url}/profile`);

export const getInstitutionPatientAvatarURL = createSelector([getInstitutionPatientURL], url => `${url}/avatar`);

export const getInstitutionPatientDiseasesURL = createSelector(
  [getInstitutionPatientURL],
  patientsURL => `${patientsURL}/assessments?group_by=diseases`
);

export const getProfessionalPatientsURL = createSelector(
  [getIsPrivatePractice, getSelectedInstitution, getProfessional],
  (isPrivatePractice, institution, professional) => {
    if (isPrivatePractice) {
      return `${professional.url}/patients`;
    }
    return `${institution.members_url}/${professional.id}/patients`;
  }
);

export const getInstitutionMemberDiseasesURL = createSelector(
  [getSelectedInstitution, getProfessional],
  (institution, professional) => `${institution.members_url}/${professional.id}/clinical-conditions`
);

export const getProfessionalPatientURL = createSelector(
  [getProfessionalPatientsURL, getPatient],
  (baseUrl, patient) => `${baseUrl}/${patient.id}`
);
export const getProfessionalPatientDiseasesURL = createSelector(
  [getProfessionalPatientURL],
  baseUrl => `${baseUrl}/diseases`
);

export const getVerifyInstitutionPatientURL = createSelector(
  [getProfessionalPatientsURL],
  baseUrl => `${baseUrl}/verify`
);

export const getClinicalTrialsInstitutionPatientURL = createSelector(
  [getProfessionalPatientsURL],
  baseUrl => `${baseUrl}/clinical_trials`
);

export const getConfirmInstitutionPatientURL = createSelector(
  [getProfessionalPatientsURL],
  baseUrl => `${baseUrl}/confirm`
);

export const getNewInstitutionPatientURL = createSelector([getProfessionalPatientsURL], baseUrl => `${baseUrl}/new`);

export const getAddInstitutionPatientURL = createSelector(
  [getProfessionalPatientsURL],
  baseUrl => `${baseUrl}/new_disease`
);

export const getProfessionalAssessmentsURL = createSelector(
  [getSelectedInstitution, getProfessional, getIsPrivatePractice],
  (institution, professional, isPrivatePractice) => {
    if (!isPrivatePractice) {
      return `${institution.members_url}/${professional.id}/assessments`;
    }
    return `${professional.url}/assessments`;
  }
);

export const BasePatientURL = '/api/patients';

export const getPatientDiseasesURLById = patientId => `${BasePatientURL}/${patientId}/diseases`;

export const getPatientDiseaseURL = createSelector([getPatient], patient => `${BasePatientURL}/${patient.id}/diseases`);

export const getAssessmentPatientDiseaseURL = createSelector(
  [getPatient, getSelectedDisease],
  (patient, disease) => `/api/patients/${patient.id}/diseases/${disease.id}/assessments`
);

export const getPatientDiseaseEditURL = createSelector(
  [getPatient, getSelectedDisease],
  (patient, disease) => `/api/patients/${patient.id}/diseases/${disease.id}`
);

export const getPatientDiseaseScoresURL = createSelector(
  [getPatient, getSelectedDisease],
  (patient, disease) => `/api/patients/${patient.id}/diseases/${disease.id}/scores`
);

export const getPatientDiseaseContextURL = createSelector(
  [getPatient, getSelectedDisease],
  (patient, disease) => `/api/patients/${patient.id}/diseases/${disease.id}/clinician-context`
);

export const getPatientBaseURL = createSelector([getPatient], patient => `/api/patients/${patient.id}`);
export const getBabyReportUrl = createSelector([getPatient, getDiseaseItems], (patient, pdiseases) => {
  const pregnancy = pdiseases.find(pd => pd.disease.slug === 'pregnancy');
  if (pregnancy) {
    return `/api/patients/${patient.id}/diseases/${pregnancy.id}/events/baby-move-report/instant-event`;
  }
  return '';
});

export const getAgreementTermsURL = createSelector(
  [getPatientDiseaseEditURL],
  patientDiseaseUrl => `${patientDiseaseUrl}/terms`
);

export const getStatusBarURL = createSelector(
  [getPatientDiseaseEditURL],
  patientDiseaseUrl => `${patientDiseaseUrl}/header-questions`
);

export const getBuildPatientDiseaseURL = (patientID, diseaseID) => `/api/patients/${patientID}/diseases/${diseaseID}`;

export const getStatusBarURLByPatient = (patientID, diseaseID) =>
  `${getBuildPatientDiseaseURL(patientID, diseaseID)}/header-questions`;

export const getReadingsURLByPatient = (patientID, diseaseID, filters = '') =>
  `${getBuildPatientDiseaseURL(patientID, diseaseID)}/readings${filters ? `?${filters}` : ''}`;

export const getCategoryReadingsURLByPatient = (patientID, diseaseID, category) =>
  `${getBuildPatientDiseaseURL(patientID, diseaseID)}/readings/${category}`;

export const getResumeExportURL = (patient, disease) =>
  `/export/patients/${patient.id}/diseases/${disease.id}/overview`;

export const getActionURL = createSelector([getSelectedDisease], disease => disease.action_url || '');

export const getScaleMeasurementURL = createSelector(
  [getPatient],
  patient => `/api/patients/${patient.id}/device-readings-classifiers`
);

export const getReadingsRangesURL = createSelector([getPatient, getDiseaseItems], (patient, pdiseases) => {
  const [disease] = pdiseases;
  return disease ? `/api/patients/${patient.id}/diseases/${disease.id}/readings-ranges` : '';
});

export const getReadingsRangesURLbyPatient = (patientID, diseaseID) =>
  patientID && diseaseID ? `/api/patients/${patientID}/diseases/${diseaseID}/readings-ranges` : '';

export const getPatientRecommendedArticlesURL = createSelector(
  [getPatient],
  patient => `/api/patients/${patient.id}/articles`
);

export const getCareDoctorURL = createSelector(
  [getSelectedInstitution, getSelectedDisease],
  (institution, pdisease) => {
    const url = `/api/institutions/${institution.id}/members?category=doctor&category=surgeon&page_size=100`;
    if (pdisease.disease) {
      return `${url}&disease=${pdisease.disease.slug}`;
    }
    return url;
  }
);

export const PROFESSIONALS_PROVIDERS_URL = '/api/professionals?category=doctor&category=surgeon&page_size=1000';

export const INSURANCE_URL = '/api/institutions?page_size=100&type=insurance&slug=multicare';

export const NOTIFICATIONS_URL = '/api/notifications';

export const NOTIFICATIONS_OPEN_URL = `${NOTIFICATIONS_URL}/open`;

export const EDUCATION_BASE_URL = 'https://cms.labs.promptly.health';

export const EDUCATION_API_URL = `${EDUCATION_BASE_URL}/api/v2`;

export const getMeasurementsURL = state => state.measurements.auth.URL;

export const getDiseaseTeamURL = (patient, pdisease) => `/api/patients/${patient.id}/diseases/${pdisease.id}/members`;
export const getPromURL = (patient, pdisease, epromType, uid) =>
  epromType
    ? `/api/patients/${patient}/diseases/${pdisease}/eprom?${epromType}=${uid}`
    : `/api/patients/${patient}/diseases/${pdisease}/eprom`;

export const getWatchListURL = createSelector([getSelectedInstitution], inst => `${inst.url}/patient-list-filters`);

export const getFilterActionsURL = (inst, patient) => `/api/institutions/${inst.id}/patients/${patient.id}/featured`;
