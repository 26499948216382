"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveTimingFromTimeline = void 0;
var dates_1 = require("./dates");
var inactiveStatus = ['completed', 'overdue'];
var getActiveTimingFromTimeline = function (timelineItem) {
    return timelineItem.source === 'patient' &&
        (0, dates_1.moment)(timelineItem.start_at).isBefore() &&
        (0, dates_1.moment)(timelineItem.end_at).isSameOrAfter(new Date(), 'day') &&
        !inactiveStatus.includes(timelineItem.status);
};
exports.getActiveTimingFromTimeline = getActiveTimingFromTimeline;
