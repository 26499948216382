import { createSelector } from 'reselect';
import { DEFAULT_DATE_FORMAT, moment } from 'utils/dates';

const defaultObject = {};

// const buildPatientDetails = props => {
//   const value = [props.national_id, props.age].reduce((p, n, index) => {
//     if (n !== undefined && n !== null && n !== '') {
//       if (index === 0) {
//         p.push(`Nº CC: ${n}`);
//       } else if (index === 1) {
//         p.push(`${n} ${window.i18next.t('years old')}`);
//       }
//     }
//     return p;
//   }, []);
//   return value.join(' | ');
// };

// Patients
export const getDoctorPatients = state => state.doctor.patients || defaultObject;
export const getDoctorPatientsItems = createSelector([getDoctorPatients], patients => patients.items);

// Follow Ups
export const getDoctorFollowUps = state => state.doctor.followUps || defaultObject;
export const getDoctorFollowUpsItems = createSelector([getDoctorFollowUps], followUps => followUps.items);

// Assessments
export const getDoctorAssessments = state => state.doctor.assessments || defaultObject;

// PROFESSIONAL USER
export const getProfessional = state => state.professional;

export const instIdURLParam = state => {
  if (!state.match) {
    return undefined;
  }
  const { instId } = state.match.params;
  return instId;
};

export const getHospitalAssessments = state => state.hospital.assessments || defaultObject;
export const getHospitalAssessmentsItems = createSelector([getHospitalAssessments], assessments => assessments.items);

export const getProfessionalInstitutions = createSelector(
  [getProfessional],
  professional => professional.institutions || defaultObject
);

export const getSelectedInstitution = createSelector([getProfessional], professional =>
  professional ? professional.selectedInstitution : defaultObject
);

export const getProfessionalMemberInfo = createSelector(
  [getSelectedInstitution],
  institution => institution.member_pk || undefined
);

export const getIsPrivatePractice = createSelector([getSelectedInstitution], institution => institution.id === 'me');

export const hasSelectedInstitution = createSelector(
  [getSelectedInstitution, getProfessional],
  (institution, professional) => Object.keys(professional).length > 0 && Object.keys(institution).length > 0
);

export const canChangeToInstitution = createSelector(
  [getProfessionalInstitutions, instIdURLParam],
  (institutions, instId) => !!institutions.find(inst => inst.id === instId)
);

export const getDoctorPatientUrl = createSelector(
  [getSelectedInstitution, getProfessional],
  (selectedInstitution, professional) => {
    if (selectedInstitution.id === 'me') {
      return `${professional.following_url}`;
    }
    return `${selectedInstitution.members_url}${professional.id}/patients`;
  }
);

export const getDoctor = state => state.doctor || defaultObject;

export const getSelectedPatient = state => state.institutionPatient.selected || defaultObject;

export const getDiseaseProfessionalDetailUrl = createSelector(
  [getSelectedInstitution, getSelectedPatient],
  (institution, patient) => `/doctor/${institution.id || ''}/patients/${patient ? patient.id : ''}`
);

export const getDiseaseInstitutionDetailsUrl = createSelector(
  [getSelectedInstitution, getSelectedPatient],
  (institution, patient) => `/hospital/${institution.id || ''}/patients/${patient ? patient.id : ''}`
);

export const getPatientInstitutionalUrl = createSelector(
  [getSelectedInstitution, getProfessional, getSelectedPatient],
  (institution, profissional, patient) =>
    `${institution.members_url}${profissional.id}/patients/${patient ? patient.id : ''}`
);

export const getEditPatient = state => state.institutionPatient.editPatient || defaultObject;

export const getEditPatientPrivateData = createSelector([getEditPatient], patient =>
  patient ? patient.private_data : defaultObject
);
export const getEditPatientURL = createSelector([getEditPatient], patient => patient.user_url);

export const getDoctorAssessmentsItems = createSelector(
  [getDoctorAssessments, getSelectedInstitution],
  (assessments, institution) =>
    assessments.items.map(a => ({
      ...a,
      // patient: {
      //   ...a.patient,
      //   details: buildPatientDetails({ national_id: a.patient.national_id, age: a.patient.age }),
      // },
      disease: {
        ...a.disease,
        created_at: moment(a.created_at).format(DEFAULT_DATE_FORMAT)
      },
      navigation: `/doctor/${institution.id}/patients/${a.patient.id}/conditions/${a.patient_disease.id}/assessments`
    }))
);
