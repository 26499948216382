import { LOADING_ADD_TEAM_MEMBER } from 'constants/actionTypes';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { DEFAULT_DATE_FORMAT, moment } from 'utils/dates';
import { getInstitutionData } from './selected_institutions';

export const getPatient = state => {
  if (state.institutionPatient && state.institutionPatient.selected) {
    return state.institutionPatient.selected;
  }
  return state.patient;
};

export const getPatientDiseaseItems = createSelector([getPatient], patient => patient.diseases);

export const getPatientTimezone = createSelector([getPatient], patient =>
  get(patient, 'preferences.regional.timezone')
);

export const getPatientOauthProviderURL = createSelector([getPatient], patient => patient.oauth_providers_url);

export const getPatientUrl = createSelector([getPatient], patient => patient.url || undefined);

export const getPatientId = createSelector([getPatient], patient => (patient ? patient.id : undefined));

export const getDiseasesData = state => state.diseases;

export const getDiseaseItems = createSelector([getDiseasesData], disease => disease.items);

export const getDiseaseById = (id, state) => {
  const existsDiseases = state.diseases && state.diseases.items.length > 0;
  const notFoundDiseaseInStore = true;

  if (existsDiseases) {
    return state.diseases.items.find(d => d.id === Number(id));
  }
  return !existsDiseases || notFoundDiseaseInStore;
};

export const getSelectedDisease = createSelector([getDiseasesData], diseases => diseases.selected);

export const getDiseaseDetails = createSelector([getSelectedDisease], patientDisease => ({
  name: patientDisease.disease ? patientDisease.disease.name : '',
  created_at: patientDisease ? moment(patientDisease.created_at).format(DEFAULT_DATE_FORMAT) : '',
  treatment: 'Cirurgia',
  hospital: patientDisease.institution ? getInstitutionData(patientDisease.institution) : {}
}));

export const getDetailDiseasesUrl = createSelector([getSelectedDisease], disease => disease.url);

export const getAddMemberUrl = createSelector([getSelectedDisease], disease => disease.add_team_member_url);

export const getDiseasesListUrl = createSelector([getPatient], patient => patient.diseases_url);

export const getHasHospitalDefined = createSelector(
  [getDiseaseDetails],
  details => Object.keys(details.hospital).length > 0
);

export const getIsLoadingPhysicianState = createSelector(
  [getDiseasesData],
  disease => disease.loading && [LOADING_ADD_TEAM_MEMBER].includes(disease.loading)
);

export const getPatientInstitutions = createSelector([getPatient], patient => {
  if (!patient || (patient && !patient.institutions)) {
    return [];
  }
  return patient.institutions.map(inst => getInstitutionData(inst));
});

export const getPatientSelectedInstitution = createSelector([getPatient], patient => {
  if (Object.keys(patient.selectedInstitution).length) {
    return {
      ...patient.selectedInstitution,
      logo: patient.selectedInstitution.branding.app ? patient.selectedInstitution.branding.app.logo_url : undefined
    };
  }
  return {};
});

export const getPatientLastAssessment = state => state.patient.lastAssessment;

export const getPatientAllAssessments = state => (state.patient?.assessments?.length ? state.patient.assessments : []);

export const getPatientTodoAssessments = state =>
  state.patient.todoAssessments.length ? state.patient.todoAssessments : [];

/**
 *  Gets the patient coa scores
 *  @param {{}} state Reducer state
 *
 *  @returns {list}
 */
export const getPatientCoas = state => {
  if (Array.isArray(state.patient.patientScores) && state.patient.patientScores.length > 0) {
    return state.patient.patientScores;
  }
  return [];
};

/**
 *  Gets the coa scores for selected patient disease
 *  @param {Object} state Reducer state
 *
 * *  @returns {any} List of COAS
 */

export const getPatientDiseaseCoas = state =>
  state.diseases?.selected && Object.keys(state.diseases.selected).length > 0 ? state.diseases.selected.scores : [];

/**
 *  Gets the patient coa scores
 *  @param {{}} state Reducer state
 *
 *  @returns {list}
 */
export const getPatientGenericCoas = patient => {
  if (Array.isArray(patient.patientScores) && patient.patientScores.length > 0) {
    return patient.patientScores.filter(s => s.is_generic);
  }
  return [];
};

/**
 *  Gets the patient selected coa object
 *  @param {{}} state Reducer state
 *
 *  @returns {{}}
 */
export const getSelectedCoa = state => {
  if (Object.keys(state.patient.selectedCoa).length) {
    return state.patient.selectedCoa;
  }
  return {};
};

export const getPatientReadings = createSelector();

export const getPatientDiseasePatientHistory = state => state.patient.diseasePatientHistory;

export const getPatientSeverityLevel = createSelector([getPatient], patient => patient.severity_level || undefined);

export const getPatientReportedSymptomsHistory = state => state.patient.reportedSymptoms;
