import { LOADING_ADD_TEAM_MEMBER } from 'constants/actionTypes';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getSelectedAssessmentReview } from 'selectors/_selected_reviews.js';
import { getEventName } from 'utils/app';
import { getActiveTimingFromTimeline } from 'utils/timings';
import { DEFAULT_DATE_FORMAT, moment } from 'utils/dates';
import { getTrans, makeTrans } from '../utils/translations';
import { getInstitutionData } from './selected_institutions';
import { getPatient } from './selected_patient';

export const getDiseasesData = state => state.diseases || {};

export const getDiseaseEvents = createSelector([getDiseasesData], disease => disease.events || []);

export const getDiseaseSelectedEvents = createSelector([getDiseasesData], diseases => diseases.selectedEvents || []);

export const getDiseaseItems = createSelector([getDiseasesData], disease => disease.items || []);

export const getDiseaseById = (id, state) => {
  const existsDiseases = state.diseases && state.diseases.items.length > 0;
  if (existsDiseases) {
    return state.diseases.items.find(d => d.id === Number(id)) || false;
  }
  return false;
};

export const getSelectedDisease = state => state.diseases.selected || {};

export const getSelectedInstitution = createSelector([getSelectedDisease], disease => disease.institution || {});

const _getEventSection = items => {
  const data = items.reduce((p, i) => {
    p[`${i.event.slug}-${i.event.id}`] = {
      event: {
        ...i.event,
        name: getEventName(`${getTrans(i.event, 'name', window.i18next.language)}`, i.event.laterality)
      },
      timings: [...(p[`${i.event.slug}-${i.event.id}`] ? p[`${i.event.slug}-${i.event.id}`].timings : []), i]
    };
    return p;
  }, {});
  return data;
};

export const getDiseaseTimings = createSelector([getSelectedDisease], disease =>
  disease.timeline
    ? _getEventSection(disease.timeline.filter(t => t.source === 'patient' && moment(t.start_at).isBefore()))
    : []
);

export const getAllDiseaseTimings = createSelector([getSelectedDisease], disease =>
  disease.timeline
    ? disease.timeline
        .filter(t => !t.event?.slug.includes('prescribed'))
        .sort((a, b) => a.timeline_start_at - b.timeline_start_at)
    : []
);

export const getLastActiveDiseaseTiming = createSelector([getSelectedDisease], disease =>
  disease.timeline
    ? disease.timeline.find(
        t => t.source === 'patient' && moment(t.start_at).isBefore() && t.end_at !== null && t.status !== 'completed'
      )
    : []
);

export const getActiveTimings = createSelector([getSelectedDisease], disease =>
  disease.timeline ? disease.timeline.filter(getActiveTimingFromTimeline) : []
);

export const getDiseaseDetails = createSelector([getSelectedDisease], patientDisease => ({
  name: patientDisease.disease ? patientDisease.disease.name : '',
  id: patientDisease.disease ? patientDisease.disease.id : undefined,
  created_at: patientDisease ? moment(patientDisease.created_at).format(DEFAULT_DATE_FORMAT) : '',
  treatment: 'Cirurgia',
  hospital: patientDisease.institution ? getInstitutionData(patientDisease.institution) : {},
  timeline: {
    patients: patientDisease.timeline
      ? _getEventSection(patientDisease.timeline.filter(t => t.source === 'patient'))
      : [],
    physician: patientDisease.timeline
      ? _getEventSection(patientDisease.timeline.filter(t => t.source !== 'clinician'))
      : []
  }
}));

export const getDiseasePromScore = disease => {
  if (Array.isArray(disease.scores) && disease.scores.length > 0) {
    return disease.scores[0];
  }
  return {};
};

/**
 *	Gets every coa's last score
 * 	@param {{}} disease Information about the disease
 *
 * 	@returns {list}
 */
export const getDiseaseCoas = disease => {
  if (Array.isArray(disease.scores) && disease.scores.length > 0) {
    return disease.scores;
  }
  return [];
};

export const getDetailDiseasesUrl = createSelector([getSelectedDisease], disease => disease.url);

export const getDetailDiseasesDiaryUrl = createSelector([getSelectedDisease], disease => `${disease.url}/diary`);

export const getAddMemberUrl = createSelector([getSelectedDisease], disease => disease.add_team_member_url);

export const getDiseasesListUrl = createSelector([getPatient], patient => patient.diseases_url || '');

export const getDiseaseAssessmentTimeLine = createSelector([getSelectedDisease], disease => ({
  patients: disease.timeline ? _getEventSection(disease.timeline.filter(t => t.source === 'patient')) : [],
  physician: disease.timeline ? _getEventSection(disease.timeline.filter(t => t.source !== 'patient')) : []
}));

export const getDiseaseEventsTimeLine = createSelector([getDiseaseAssessmentTimeLine], timings => timings.physician);

export const getHasHospitalDefined = createSelector(
  [getDiseaseDetails],
  details => Object.keys(details.hospital).length > 0
);

export const getIsLoadingPhysicianState = createSelector(
  [getDiseasesData],
  disease => disease.loading && [LOADING_ADD_TEAM_MEMBER].includes(disease.loading)
);

export const getTreatmentListOptions = createSelector([getDiseasesData], disease =>
  disease.treatmentsOptions.length > 0 ? [{ options: [...disease.treatmentsOptions] }] : []
);

export const getSelectedTreatmentEventsList = createSelector(
  [getDiseasesData],
  disease => disease.selectedTreatmentEvents || []
);

export const getEventsAndTreatments = createSelector([getDiseaseEvents, getDiseasesData], (events, disease) => [
  { options: [...events, ...disease.treatmentsOptions, ...disease.examsOptions], title: makeTrans('Event') }
]);

// TODO: DEPRECATE
export const getPatientEventsAndTreatments = createSelector([getEventsAndTreatments], eventsAndTreatments => {
  const patientEventsAndTreatments = eventsAndTreatments.map(et => ({
    ...et,
    options: et.options.filter(
      option => !option.userSource || option.userSource === 'patient' || option.userSource === 'both'
    )
  }));

  return patientEventsAndTreatments;
});

// TODO: DEPRECATE
// used in green card on disease detail. Patient.
export const getClinicianEventsAndTreatments = createSelector([getEventsAndTreatments], eventsAndTreatments => {
  const clinicianEventsAndTreatments = eventsAndTreatments.map(et => ({
    ...et,
    options: et.options.filter(
      option => !option.userSource || option.userSource === 'clinician' || option.userSource === 'both'
    )
  }));

  return clinicianEventsAndTreatments;
});

export const getActionBarItems = createSelector([getDiseasesData], disease => disease.actionBar);

export const getPatientActionBarItems = createSelector([getDiseasesData], disease => [
  {
    options: disease.actionBar
  }
]);

export const sortChart = (a, b) => {
  const dateA = a.date;
  const dateB = b.date;

  const diff = new Date(dateA) - new Date(dateB);

  // if same day order by id
  if (diff === 0) {
    return b.id < a.id ? -1 : 1;
  }
  return diff;
};

export const getDiseaseChartData = createSelector(
  [getSelectedDisease, getSelectedTreatmentEventsList],
  (disease, events) =>
    [
      ...get(disease, 'scores[0].scores', []),
      ...events.map(e => ({
        ...e,
        date: moment(e.event_at).format('L'),
        event: {
          name: e.name
        }
      }))
    ].sort(sortChart)
);

export const getSelectedTreatment = createSelector([getSelectedAssessmentReview], selectedCro => {
  if (selectedCro && Object.keys(selectedCro).length) {
    return selectedCro.event;
  }
  return {};
});

export const getMovementReport = createSelector([getDiseasesData], disease => disease.pregnancy.movementReport || {});

export const getStatusBar = createSelector([getDiseasesData], disease => disease.statusBar || {});

export const getReadings = createSelector([getDiseasesData], disease => disease.readings || []);

export const getDiseaseDetail = createSelector([getDiseasesData], disease => disease.pdisease || {});

export const getDevelopmentInfo = createSelector([getDiseasesData], disease => disease.developmentInfo || []);

export const getPregnancyBaseDate = createSelector([getDiseasesData], disease => disease.pregnancyBaseDate);

export const getEducationUrls = createSelector(
  [getSelectedDisease],
  selectedDisease => selectedDisease.education_urls || {}
);

export const getDiseaseEventsTimeline = createSelector([getDiseasesData], disease => disease.eventsTimeline || []);

export const getReadingsRanges = createSelector([getDiseasesData], disease => disease.ranges || []);
