import { createSelector } from 'reselect';

export const getAssessmentsReviews = state => state.reviews;

export const getAssessmentsReviewsErrors = createSelector([getAssessmentsReviews], reviews => reviews.errors);

export const getSelectedAssessmentReview = createSelector([getAssessmentsReviews], reviews => reviews.selected);

export const getDiseaseRegistry = createSelector([getAssessmentsReviews], reviews => reviews.registry);

export const getReviewsNav = createSelector([getAssessmentsReviews], reviews =>
  Object.keys(reviews.items).map(key => ({
    event: {
      name: reviews.items[key].name,
      slug: key,
      date: reviews.items[key].event_at,
      eventName: reviews.items[key].name,
      pdiseaseEventId: reviews.items[key].id,
      pdiseaseId: reviews.items[key].patient_disease,
      ...reviews.items[key]
    },
    patients: reviews.items[key].timings.filter(r => r.source === 'patient'),
    clinicians: reviews.items[key].timings.filter(r => r.source !== 'patient')
  }))
);

const getReview = review => (review.source === 'patient' ? review.resume : review.questionnaire);

export const getSelectedQuestionnaire = createSelector([getSelectedAssessmentReview], review => ({
  title: review ? review.title : '',
  sections: [
    {
      questionnaire: review ? getReview(review) : []
    }
  ]
}));

export const getSelectedExam = createSelector([getAssessmentsReviews], reviews => reviews.selectedExam || {});

export const getPatientDiseaseExams = createSelector([getAssessmentsReviews], reviews => reviews.exams || []);

export const getQuestionKeys = createSelector([getAssessmentsReviews], reviews => reviews.questionKeys || []);
