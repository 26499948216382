import { ColorUtils } from '@promptlylabs/ui-kit';

export const { getColorRGBA, getRGBACode, pSBC } = ColorUtils;

/**
 * Cheat sheet:
 * ============
 * getColorRGBA => args: (color, opacity = 1)
 * getRGBACode => args: (color, opacity = 1)
 * ============
 */
