import { pSBC } from 'utils/colors';

const getTheme = (base, customs) => ({
  ...base,
  ...customs
});
export default getTheme;

export const getThemeFont = (theme, variation) =>
  theme && theme.promptlyFonts && theme.promptlyFonts[variation]
    ? `font-family: ${theme.promptlyFonts[variation]};`
    : null;

export const createBrandedThemes = (uiKitTheme, skeletonTheme, color) => {
  const newUiKitTheme = getTheme(uiKitTheme, {
    palette: {
      primary: color,
      primary900: pSBC(color, -10),
      primary600: pSBC(color, 0),
      primary500: pSBC(color, 10)
    }
  });

  const newSkeletonTheme = {
    ...skeletonTheme,
    palette: {
      ...skeletonTheme.palette,
      primary: {
        ...skeletonTheme.palette.primary,
        main: color,
        light: pSBC(color, +10),
        dark: pSBC(color, -10)
      }
    }
  };

  return {
    uiKitTheme: newUiKitTheme,
    skeletonTheme: newSkeletonTheme
  };
};
